@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
  box-sizing:border-box;
}

:root {
  --glow-color: hsl(186 100% 69%);
  font-family: 'sans serif';
  color: black;
}

.pacifico {
  font-family: 'Pacifico', cursive;
}

body {
  margin:0;
  min-height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background:url('../img/background.png');
  background-size: cover;
  /* background-image: linear-gradient(to bottom, rgb(136, 221, 236), #13741b); */
  perspective: 1000px;
  overflow:auto;
}

.gallery {
  --g: 8px; /* the gap */
  width: 450px;
  display: grid;
  justify-content:center;
  align-items:center;
  aspect-ratio: 1;
  grid: auto-flow ;
  gap: var(--g);
  overflow: visible;
}
.gallery img {
  width: 100%;
  height: 100%;
  border-radius: 0.45em;
  object-fit: cover;
  filter: grayscale();
  cursor: pointer;
  transition: .5s;
}
.gallery img:hover {
  filter: grayscale(0);
}
.gallery .gallery-item:nth-child(2) {
  grid-area: 1/2/span 2/span 2;
  clip-path: polygon(0 0,100% 0,100% 100%,calc(50% + var(--g)/4) 100%,0 calc(50% - var(--g)/4))
}
.gallery .gallery-item:nth-child(3) {
  grid-area: 2/1/span 2/span 2;
  clip-path: polygon(0 0,calc(50% - var(--g)/4) 0,100% calc(50% + var(--g)/4),100% 100%,0 100%);
}

/* Media Query for Small Screens with Taller Height */
@media only screen and (max-width: 600px) {
  .gallery {
    width: 80%;
    grid-template-columns: 15rem; /* Display one column for small screens */
    grid-auto-rows: 15rem; /* Each row takes equal height */ 
  }

  .gallery .gallery-item {
    grid-area: auto; /* Reset grid area */
    clip-path: none; /* Remove clip-path for smaller screens */
  }

  .gallery .gallery-item:nth-child(2),
  .gallery .gallery-item:nth-child(3) {
    clip-path: none; /* Remove clip-path for specific items */
    display: none;

  }
}


.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  /* padding: 0.35em 1em; */
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  /* animation: border-flicker 2s linear infinite; */
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  /* animation: text-flicker 3s linear infinite; */
}

.faulty-letter {
  opacity: 0.5;
  /* animation: faulty-flicker 2s linear infinite; */
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  /* background: var(--glow-color); */
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
    perspective: None;
  }
  .glowing-btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.7;
    filter: blur(1em);
    transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
    background: None;
    pointer-events: none;
  }
}

h1 {
  color: #173494;
  text-shadow: 0 0 0.125em hsl(0 0% 20% / 0.3), 0 0 0.45em var(--glow-color);
	margin: auto;
	letter-spacing: 0.0015em;
  /* font-family: 'Inter', cursive; */
	/* text-shadow: 0 1px #8da1ff, -1px 0 #c0cbff, -1px 2px #8da1ff, -2px 1px #c0cbff,
		-2px 3px #8da1ff, -3px 2px #c0cbff, -3px 4px #8da1ff, -4px 3px #c0cbff,
		-4px 5px #8da1ff, -5px 4px #c0cbff, -5px 6px #8da1ff, -6px 5px #c0cbff,
		-6px 7px #8da1ff, -7px 6px #c0cbff, -7px 8px #8da1ff, -8px 7px #c0cbff; */
	text-align: center;
}


/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  /* padding: 12px 16px; */
  text-decoration: none;
}

.gallery-item:hover .dropdown-content {
  display: block;
  overflow: visible;
}

